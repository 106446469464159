import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getRekapStunting(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },


        total() {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/total').then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addRekapStunting(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/rekap-stunting/create', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateRekapStunting(_, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put('/rekap-stunting/update/' + id, data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteRekapStunting(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/rekap-stunting/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/rekap-stunting/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getKecamatan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kecamatan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        importRekapStunting(_, file) {
            return new Promise((resolve, reject) => {

                const formData = new FormData();
                formData.append('file', file);
                const headers = { 'Content-Type': 'multipart/form-data' };

                let config = {
                    headers
                }
  
                axios.post('/rekap-stunting/import', formData, config).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseBySurvey(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/persentase-by-survey', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseByKecamatan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/persentase-by-kecamatan', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseByKecamatanSurvey(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/rekap-stunting/persentase-by-kecamatan-survey', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}